<template>
  <DataTable :options="options" />
</template>

<script>
export default {
  data() {
    return {
      options: {
        addNew: true,
        action: {
          child: 'attribute',
          showChildList: true,
        },
        actions: [
          {
            text: 'Enable',
            body: { isActive: true },
          },
          {
            text: 'Disable',
            body: { isActive: false },
          },
          {
            text: 'Delete',
            body: { isDeleted: true },
          },
        ],
        headers: [
          { text: 'Name', value: 'name', filter: true, component: { name: 'dtView', props: { name: 'feature' } } },
          { text: 'Icon', value: 'icon', width: '50px', component: { name: 'dtIcon' } },
          { text: 'Code', value: 'code', width: '50px' },
          {
            text: 'Enabled',
            value: 'isActive',
            width: '50px',
            sortable: false,
            align: 'center',
            component: { name: 'dtCheckbox', props: { action: true } },
          },
        ],
      },
    };
  },
};
</script>
